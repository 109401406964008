import React from 'react'
// import { Fotos } from '../paginaPublica/7Fotos'
import { NavBar_afiliacion } from '../afiliacion/preAfiliacion/NavBar_Preafiliacion'
import { Box, Container, Typography, Grid, Card, CardActionArea, CardMedia, CardContent } from '@mui/material'
import { multimediaFeed } from '../paginaPublica/Multimedia'

export const ContenidoMultimedia = () => {
  return (
    <div>
      <NavBar_afiliacion/>
      <Box sx={{marginTop: '3%'}}>
        <Container>
          <Typography
            color='primary'
            sx={{
              fontWeight: 700, width: '65%',
              fontSize: {
                xs: "1.3rem", // Tamaño de fuente para pantallas pequeñas (extra small)
                sm: "1.5rem", // Tamaño de fuente para pantallas pequeñas (small)
                md: "2rem",   // Pantallas medianas
                lg: "2.5rem", // Pantallas grandes
              },
              // marginBlock: "1%",
            }}
          >
            Nuestro día a día
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          {multimediaFeed.map((referencia, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card sx={{ maxWidth: "100%", height: '370px'}}>
                {/* <CardActionArea> */}
                  <CardMedia
                    component="img"
                    height="140"
                    image={referencia.img}
                    alt={referencia.titulo}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {referencia.titulo}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                      {referencia.texto}
                    </Typography>
                  </CardContent>
                {/* </CardActionArea> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Box sx={{marginTop: '3%', marginBlock: '2%'}}>
        <Container>
          <Typography
            sx={{
              fontSize: {
                xs: "1rem", // Tamaño de fuente para pantallas pequeñas (extra small)
                sm: "1.2rem", // Tamaño de fuente para pantallas pequeñas (small)
                md: "1.5em",   // Pantallas medianas
                lg: "1.8rem", // Pantallas grandes
              },
              marginBlock: "1%",
            }}
          >
            Más de nosotros
          </Typography>

          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <iframe 
              width="850" 
              height="485" 
              // width="60%" 
              // height="560vh" 
              src="https://www.youtube.com/embed/PpZBNzKfcaw?si=7BBDRyZei5x8M9d9"
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>
            <iframe
              width="350"
              height="440"
              src="https://www.youtube.com/embed/i1Si4A1REBo"
              title="YouTube Short player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </Box>

        </Container>
      </Box>
    </div>
  )
}
