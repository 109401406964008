import React from 'react';
import { Box, useTheme, useMediaQuery, Card, CardActionArea, CardMedia, CardContent, Typography, CardHeader, IconButton, Avatar, Grid } from '@mui/material';
import referencias from './DMreferencias';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

export const Referencias = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBlock: '5vh'}}>
        {isSmallScreen &&
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ display: 'flex', justifyContent: 'center', gap: '2vw', overflow: 'hidden' }}>
                {referencias.map((referencia, index) => ( 
                    <Card key={index} sx={{ maxWidth: 180 }}>
                        {/* <CardActionArea> */}
                            <CardHeader
                              avatar={
                                <Avatar sx={{ bgcolor: referencia.color }} aria-label="recipe">
                                  {referencia.avatar}
                                </Avatar>
                              }
                              title={referencia.afiliado}
                              subheader={referencia.fecha}
                            />
                            <CardMedia
                              component="img"
                              height="140"
                              image={referencia.img}
                              alt={referencia.servicio}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {referencia.servicio}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {referencia.texto}
                                </Typography>
                            </CardContent>
                        {/* </CardActio-nArea> */}
                    </Card>
                ))}
            </Grid>
        }
        {isMediumScreen &&
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ display: 'flex', justifyContent: 'center', gap: '2vw', overflow: 'hidden' }}>
                {referencias.map((referencia, index) => ( 
                    <Card key={index} sx={{ maxWidth: 250 }}>
                        {/* <CardActionArea> */}
                            <CardHeader
                              avatar={
                                <Avatar sx={{ bgcolor: referencia.color }} aria-label="recipe">
                                  {referencia.avatar}
                                </Avatar>
                              }
                              title={referencia.afiliado}
                              subheader={referencia.fecha}
                            />
                            <CardMedia
                              component="img"
                              height="140"
                              image={referencia.img}
                              alt={referencia.servicio}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {referencia.servicio}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {referencia.texto}
                                </Typography>
                            </CardContent>
                        {/* </CardActionArea> */}
                    </Card>
                ))}
            </Grid>
        }
        {isLargeScreen && (
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2vw', overflow: 'hidden' }}>
                {referencias.map((referencia, index) => ( 
                    <Card key={index} sx={{ maxWidth: 345 }}>
                        {/* <CardActionArea> */}
                            <CardHeader
                              avatar={
                                <Avatar sx={{ bgcolor: referencia.color }} aria-label="recipe">
                                  {referencia.avatar}
                                </Avatar>
                              }
                              title={referencia.afiliado}
                              subheader={referencia.fecha}
                            />
                            <CardMedia
                              component="img"
                              height="140"
                              image={referencia.img}
                              alt={referencia.servicio}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {referencia.servicio}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {referencia.texto}
                                </Typography>
                            </CardContent>
                        {/* </CardActionArea> */}
                    </Card>
                ))}
            </Box>
        )}
        {isExtraLargeScreen && (
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2vw', overflow: 'hidden' }}>
                {referencias.map((referencia, index) => ( 
                    <Card key={index} sx={{ maxWidth: 345 }}>
                        {/* <CardActionArea> */}
                            <CardHeader
                              avatar={
                                <Avatar sx={{ bgcolor: referencia.color }} aria-label="recipe">
                                  {referencia.avatar}
                                </Avatar>
                              }
                              title={referencia.afiliado}
                              subheader={referencia.fecha}
                            />
                            <CardMedia
                              component="img"
                              height="140"
                              image={referencia.img}
                              alt={referencia.servicio}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {referencia.servicio}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {referencia.texto}
                                </Typography>
                            </CardContent>
                        {/* </CardActionArea> */}
                    </Card>
                ))}
            </Box>
        )}
    </Box>
  )
}
