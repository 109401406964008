import React from 'react'
import { Box } from '@mui/material'
import { NavBar } from './NavBar'
import { Principal } from './1Principal'
import { Bienvenidos } from './2Bienvenidos'
import { Convenios } from './3Convenios'
import { Servicios } from './4Servicios'
import { Beneficios } from './5Beneficios'
import { Promociones } from './6Promociones'
import { Video } from './Video'
import { Referencias } from './Referencias'
// import { Contactanos } from './7Contactanos'
import { Seguros } from './7Seguros'
import { Footer } from './Footer'
import { WhatsappChatButton } from './8WhatsappChatButton'
import { Fotos } from './7Fotos'

export const ScreenPaginaPublica = () => {
  return (
    <Box>
      <NavBar/>
      <Principal/>
      <Bienvenidos/>
      <Convenios/>
      <Servicios/>
      <Beneficios/>
      <Promociones/>
      {/* <Video/> */}
      <Referencias/>
      <Fotos display= 'true'/>
      <Seguros/>
      <WhatsappChatButton/>
              {/* <Contactanos/> */}
      <Footer/>
    </Box> 
  )
}
