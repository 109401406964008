import React from 'react'
import { Typography, Box, Card, CardMedia, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import multimedia from './Multimedia';

export const Fotos = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Muestra 3 tarjetas a la vez
    slidesToScroll: 1, // Desliza una tarjeta por clic
    responsive: [
      {
        breakpoint: 1024, // Para pantallas medianas
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Para pantallas pequeñas
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Box sx={{ margin: "auto", overflow: "hidden", backgroundColor: '#F5F5F5',}}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ maxWidth: "80%" }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                color='primary'
                sx={{            
                  fontWeight: 700,
                  fontSize: {
                    xs: "1.3rem", // Tamaño de fuente para pantallas pequeñas (extra small)
                    sm: "1.5rem", // Tamaño de fuente para pantallas pequeñas (small)
                    md: "2rem",   // Pantallas medianas
                    lg: "2.5rem", // Pantallas grandes
                  },
                  marginBlock: "1%",
                }}
              >
                Nuestro día a día
              </Typography>
            </Box>
            <Slider {...settings}>
              {multimedia.map((referencia, index) => (
                <Card key={index} 
                  sx={{ 
                    maxWidth: '90%',
                    marginX: '5%',   
                    height: '320px'
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={referencia.img}
                      alt={referencia.titulo}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {referencia.titulo}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        {referencia.texto}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Slider>
            <Typography
              sx={{
                fontSize: {
                  xs: ".8rem", // Tamaño de fuente para pantallas pequeñas (extra small)
                  sm: ".9rem", // Tamaño de fuente para pantallas pequeñas (small)
                  md: "1rem",   // Pantallas medianas
                  lg: "1.2rem", // Pantallas grandes
                },
                marginBlock: {
                  xs: "5%",
                  sm: "4%",
                  md: "3%",
                  lg: "2%",
                },
                marginLeft: "2%",
                textAlign: "left", // Centra el texto horizontalmente
              }}
            >
              Conocer <Link to="/contenido_multimedia"> más </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
