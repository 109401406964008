import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';

export const Video = () => {
  
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    return (
    <Box>
        {isSmallScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <iframe 
                        width="560" 
                        height="315" 
                        // width="100%" 
                        // height="325vh"
                        src="https://www.youtube.com/embed/PpZBNzKfcaw?si=7BBDRyZei5x8M9d9"
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen
                    />
                </Box>
            </Box>
        }
        {isMediumScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <iframe 
                        width="625" 
                        height="350" 
                        // width="80%" 
                        // height="395vh"
                        src="https://www.youtube.com/embed/PpZBNzKfcaw?si=7BBDRyZei5x8M9d9"
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen
                    />
                </Box>
            </Box>
        }
        {isLargeScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <iframe 
                        width="650" 
                        height="370" 
                        // width="60%" 
                        // height="375vh"
                        src="https://www.youtube.com/embed/PpZBNzKfcaw?si=7BBDRyZei5x8M9d9"
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen
                    />
                </Box>
            </Box>
        }
        {isExtraLargeScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBlock: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <iframe 
                        width="850" 
                        height="485" 
                        // width="60%" 
                        // height="560vh" 
                        src="https://www.youtube.com/embed/PpZBNzKfcaw?si=7BBDRyZei5x8M9d9"
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen
                    />
                </Box>
            </Box>
        }
    </Box>
  )
}
    