const referencias = [
    {
      afiliado: 'Alí Zauco Coria',
      avatar: 'AZ',
      color: 'green',
      fecha: 'Julio 07, 2024',
      servicio: 'Funeraria canina',
      texto: 'El servicio fue respetuoso y de gran apoyo. Agradezco la atención recibida, recomendando este servicio para una despedida digna de las mascotas.',
      img: 'https://petsinthesky.com.mx/wp-content/uploads/2023/07/cremacion-para-mascotas-individual.jpeg',
    },
    {
      afiliado: 'Luis Santos del Angel',
      avatar: 'LS',
      color: 'blue',
      fecha: 'Agosto 01, 2023',
      servicio: 'Seguro de coche',
      texto: 'El seguro respondió rápidamente tras mi accidente. El proceso fue sencillo y sin complicaciones. Estoy satisfecho con el servicio.',
      img: 'https://imggraficos.gruporeforma.com/2024/05/seguro-auto-2.jpg',
    },
    {
      afiliado: 'Keila Córdova Pichardo',
      avatar: 'KC',
      color: 'pink',
      fecha: 'Julio 07, 2024',
      servicio: 'Seguro de gastos médicos',
      texto: 'El seguro fue esencial. El proceso fue rápido y me autorizaron los tratamientos sin problemas. Lo recomiendo para cuidado médico.',
      img: 'https://www.barron.com.mx/wp-content/uploads/2022/03/Como-funciona-el-Seguro-de-Gastos-Medicos-Mayores.jpg',
    }
  ];
  
  export default referencias;
  