const multimedia = [
    // {
    //   img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/sindicato_evento1',
    //   titulo: 'Sindicato por méxico',
    //   texto: 'Sindicato por méxico, trabajando por ti.'
    // },
    // {
    //   img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/Sindicato_evento2',
    //   titulo: 'Sindicato por méxico',
    //   texto: 'Sindicato por méxico, trabajando por ti.'
    // }, 
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/sindicato_evento1',
      titulo: 'Firma de nueva asociación con trabajadores del transporte en Morelia',
      texto: 'Un paso adelante para proteger los derechos de cientos de transportistas en Michoacán.'
    },
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/Sindicato_evento2',
      titulo: 'Jornada de capacitación para maestros sindicalizados',
      texto: 'Impulsando el desarrollo profesional y la educación de calidad en México.'
    },    
];  
export default multimedia;
  
export const multimediaFeed = [
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/sindicato_evento1',
      titulo: 'Firma de nueva asociación con trabajadores del transporte en Morelia',
      texto: 'Un paso adelante para proteger los derechos de cientos de transportistas en Michoacán.'
    },
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/Sindicato_evento2',
      titulo: 'Jornada de capacitación para maestros sindicalizados',
      texto: 'Impulsando el desarrollo profesional y la educación de calidad en México.'
    },    
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/sindicato_evento1',
      titulo: 'Convenio de colaboración con empresas locales en Jalisco',
      texto: 'Más beneficios para los trabajadores del sector manufacturero.'
    },
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/Sindicato_evento2',
      titulo: 'Asamblea general para presentar el plan 2025 del sindicato',
      texto: 'Líderes y afiliados unidos por un futuro con mejores condiciones laborales.'
    },    
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/sindicato_evento1',
      titulo: 'Entrega de reconocimientos a líderes sindicales destacados',
      texto: 'Celebrando el esfuerzo y la dedicación de quienes impulsan los derechos laborales.'
    },
    {
      img: 'https://xmex-media.nyc3.digitaloceanspaces.com/media/Sindicato_evento2',
      titulo: 'Acuerdo para beneficios médicos de trabajadores del sector agrícola',
      texto: 'Un convenio histórico que mejorará la calidad de vida de miles de familias campesinas.'
    }    
];  
